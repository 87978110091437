.header {
    position: fixed;
    top: 10px;
    left: 0;
    padding: 20px 0;
    width: 100%;

    .link-contain {
        max-width: fit-content;
        margin: 0 auto;
        display: flex;
        text-align: center;

        .link-item {
            position: relative;
            display: block;
            font-size: 1.05rem;
            transition-duration: .5s;
            opacity: .65;

            a {
                color: black;
                transition-duration: .5s;
            }

            & + .link-item {
                margin-left: 5vw;
            }

            & .underline-text {
                position: absolute;
                bottom: -2px;
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
                width: 0;
                height: 1px;
                background-color: black;
                transition-duration: .25s;
            }

            &:hover .underline-text {
                width: 100%;
            }
            
            &:hover, &:active {
                opacity: 1;
            }

            &:active {
                transform: scale(0.9) !important;
                opacity: 1;
            }

            &.active {
                transform: scale(1.3) !important;
                opacity: 1;
            }
        }
    }
}

.small-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none;

    .siteName {
        position: fixed;
        top: 20px;
        left: 20px;
        margin: 0;
        white-space: nowrap;
        transition-duration: .5s;
        // text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .pageName {
        position: fixed;
        top: 20px;
        right: 20px;
        margin: 0;
        white-space: nowrap;
        transition-duration: .5s;
        // text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}

.socialLink {
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    
    .sub {
        display: flex;
    }
    
    a {
        display: block;
        color: white;
        font-size: 1.5rem;
        transition-duration: .25s;

        &:hover {
            transform: scale(1.2);
        }

        &:active {
            transform: scale(1.1);
        }
    }

    a + a {
        margin-left: 20px;
    }
}

@media screen and (max-width: 768px) {
    .small-nav {
        .siteName {
            display: none;
        }

        .pageName {
            left: 20px;
            right: unset;
        }
    }
}
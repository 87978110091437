.container {
    margin: 20px auto;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    grid-gap: 5px; */
    text-align: center;
}

.container .item {
    display: inline-block;
    margin: 20px;
    max-width: calc(100% - 40px);
    width: 250px;
    text-align: center;
}

.container .item .imgContain {
    margin-bottom: 5px;
    width: 100%;
    height: 150px;
    background-color: #313131;
    border-radius: 10px;
    overflow: hidden;
}

.container .item .imgContain .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container .item .title {
    font-weight: bold;
    font-size: 19px;
}

.modal {
    width: 100%;
    text-align: center;
}

.modal .logo {
    width: 100%;
    height: 150px;
    background-color: rgba(0, 0, 0, .5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}

.modal .content {
    padding: 10px 20px;
    max-height: 600px;
    overflow: hidden auto;
}
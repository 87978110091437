.about-page {
    // padding-top: 80px;
    width: 100vw;
    min-height: 100vh;
    // background-color: rgb(201, 204, 162); //#245476;
    background: rgb(74,70,146);
    background: linear-gradient(158deg, rgba(74,70,146,1) 0%, rgba(9,9,121,1) 35%, rgba(147,1,1,1) 100%);
    color: white; //#ffa700;
}

